import { useQuery, useQueryClient } from '@tanstack/react-query'
import { requestGET } from '../services/request'

export const useUser = (enabled = false) =>
  useQuery<any>({
    queryKey: [`user`],
    queryFn: async () => {
      return requestGET(`/user`)
    },
    enabled,
  })

export const useM2MToken = () => {
  const queryClient = useQueryClient()

  return {
    getToken: () =>
      queryClient.fetchQuery({
        queryKey: [`adminToken`],
        queryFn: async () =>
          await requestGET(`organization/m2mTokenFromAdminToken`),
      }),
  }
}
