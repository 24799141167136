import React, { useMemo } from 'react'
import {
  AppShell,
  Text,
  Group,
  UnstyledButton,
  ThemeIcon,
  ScrollArea,
} from '@mantine/core'
import {
  IconIdBadge2,
  IconBuilding,
  IconBuildings,
  IconUsers,
  IconAdjustmentsHorizontal,
  IconChartHistogram,
} from '@tabler/icons-react'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { useLocation } from '@reach/router'
import { useUser } from '../hooks/user'
import { useOrganizationView } from './organizationView/useOrganizationView'
import {
  useIsAssessorOrganization,
  useIsHeartEyeOrganization,
  useIsTopLevelOrganization,
} from '../hooks/organizations'

const SideMenu = () => {
  const { t } = useTranslation()

  const { data: user } = useUser(true)

  const { orgData } = useOrganizationView()
  const isHearteyeTenant = useIsHeartEyeOrganization()

  const isTopLevelOrganization = useIsTopLevelOrganization()
  const isEcgRecordingOrgs = useMemo(() => {
    return (
      !!(
        orgData?.assessorOrgIds &&
        Array.isArray(orgData?.assessorOrgIds) &&
        orgData?.assessorOrgIds.length > 0
      ) && isHearteyeTenant
    )
  }, [orgData?.assessorOrgIds])

  const { isAssessor } = useIsAssessorOrganization()

  const location = useLocation()

  const ICON_SIZE = 24

  const selectedMenuItems = useMemo(
    () =>
      [
        {
          label: t(`menu.organization`),
          path: `/organization`,
          icon: <IconBuilding size={ICON_SIZE} />,
        },
        (isTopLevelOrganization ||
          user?.roles?.includes(`ProductAdmin`) ||
          user?.roles?.includes(`SuperAdmin`)) && {
          label: t(`menu.organizations`),
          path: `/clients`,
          icon: <IconBuildings size={ICON_SIZE} />,
        },
        !isAssessor && {
          label: t(`menu.usage`),
          path: `/usage`,
          icon: <IconChartHistogram size={ICON_SIZE} />,
        },
        !isHearteyeTenant && {
          label: t(`menu.projects`),
          path: `/project`,
          icon: <IconAdjustmentsHorizontal size={ICON_SIZE} />,
        },
        {
          label: t(`menu.members`),
          path: `/members`,
          icon: <IconIdBadge2 size={ICON_SIZE} />,
        },
        (!isAssessor || isTopLevelOrganization) && {
          label: t(`menu.patients`),
          path: `/patients`,
          icon: <IconUsers size={ICON_SIZE} />,
        },
        (isTopLevelOrganization || isEcgRecordingOrgs) &&
          isHearteyeTenant && {
            label: t(`menu.ecgs`),
            path: `/experiments`,
            icon: <IconBuilding size={ICON_SIZE} />,
          },
        isAssessor &&
          !isTopLevelOrganization && {
            label: t(`menu.ecgs`),
            path: `/assessments`,
            icon: <IconBuilding size={ICON_SIZE} />,
          },
      ]
        .filter(Boolean)
        .reverse(),
    [
      isHearteyeTenant,
      isTopLevelOrganization,
      user?.roles,
      isEcgRecordingOrgs,
      isAssessor,
      t,
    ],
  )

  return (
    <AppShell.Navbar>
      <ScrollArea scrollbars="y">
        <AppShell.Section grow p="xs">
          {selectedMenuItems.map(({ label, path, icon }, index) => {
            return (
              <UnstyledButton component={Link as any} to={path} key={index}>
                <Group
                  style={{
                    flexDirection: `column`,
                    display: `flex`,
                    width: `100%`,
                    padding: `10px`,
                    justifyContent: `center`,
                    textAlign: `center`,
                  }}
                >
                  <ThemeIcon
                    variant={
                      location.pathname.includes(path) ? `filled` : `light`
                    }
                    size="xl"
                  >
                    {icon}
                  </ThemeIcon>
                  <Text size="md">{label}</Text>
                </Group>
              </UnstyledButton>
            )
          })}
        </AppShell.Section>
      </ScrollArea>
    </AppShell.Navbar>
  )
}

export default SideMenu
